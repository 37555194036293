import * as React from 'react'
import BaseLayoutTool from '../../components/base-layout-tool'

const SplitSpreadsheetPage = () => {
    return (
        <BaseLayoutTool 
        pageTitle="Split Spreadsheet | App " 
        pageDescription="Split a spreadsheet file into a defined number of equally-sized smaller spreadsheet files, each with the same heading using this TNNL tool. Works with csv and Excel files."
        toolLink="https://dfsplit-ne2vtntboq-ew.a.run.app"
        />
    )
}

export default SplitSpreadsheetPage